.open_normal {
    font-family: OpenSansNormal;
}

.open_semibold {
    font-family: OpenSansSemiBold;
}

.open_bold {
    font-family: OpenSans-Bold;
}

.open_light {
    font-family: OpenSansLight;
}

.open_extrabold {
    font-family: OpenSansExtraBold;
}





.right_round_bg {
    border-radius: 20px 0px 0px 20px;
    background: var(--linog, linear-gradient(132deg, #2D8DED 0%, #1F63A7 100%));
}



.desc_left_wrap {
    background-image: url(../images/left_rounded_bg.svg);
    background-repeat: no-repeat;
    height: 285px;
    color: white;
}


.log_large {
    width: 190px !important;
}

.bg-main-xl {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/home-banner-bg.png);
    width: 1440px;
    height: 600px;
}

.bg-main {
    background: url(https://arsome.nyc3.cdn.digitaloceanspaces.com/assets/home-banner-bg.png) no-repeat;
    background-size: contain;
    width: 834px;
    height: 348px;
}

.scrollUp {
    background: #000 none repeat scroll 0 0;
    border-radius: 2px;
    bottom: 35px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    color: #fff;
    font-size: 20px;
    height: 34px;
    line-height: 30px;
    opacity: 0.6;
    right: 25px;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 34px;
}

.text-yellow {
    color: #FCDC3E !important;
}